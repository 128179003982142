<template>
  <div class="HomeView">
    <div class="top">
      <div class="icon" />
      <div class="title">
        {{ $t(`systems.${config.systemId}.name`) }}
      </div>
      <div
        v-if="hasSubtitle"
        class="subtitle">
        {{ $t(`systems.${config.systemId}.subtitle`) }}
      </div>
      <div
        class="exitButton --global-clickable"
        @click="exit()">
        <svgicon
          class="exitIcon"
          icon="dashboard-icon" />
      </div>
    </div>
    <div class="middle">
      <div
        class="text"
        v-html="$t(`systems.${config.systemId}.intro`)" />
    </div>
    <div class="bottom">
      <div class="buttonGroup">
        <div
          class="button --global-clickable"
          @click="$router.push('learn')">
          <!-- {{ $t('learn', {system: $t(`systems.${config.systemId}.name`)}) }} -->
          {{ $t('learn') }}
        </div>
        <div
          class="button --global-clickable"
          @click="$router.push('practice')">
          {{ $t('practice') }}
        </div>
      </div>
      <div class="secondaryButtons">
        <div
          class="buttonSecondary --global-clickable"
          @click="$router.push('browse')">
          <svgicon
            class="buttonSecondaryIcon"
            icon="library2" />
          <div class="buttonSecondaryLabel">
            {{ $t('library') }}
          </div>
        </div>
        <div
          v-if="hasArticle"
          class="buttonSecondary --global-clickable"
          @click="showArticlePopup()">
          <svgicon
            class="buttonSecondaryIcon"
            icon="article" />
          <div class="buttonSecondaryLabel">
            {{ $t('instructions') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
library: 'Library'
library_no: 'Bibliotek'
instructions: 'Instructions'
instructions_no: 'Instruksjoner'
# learn: 'Learn {system}'
# learn_no: 'Lær {system}'
learn: 'Learn'
learn_no: 'Lær'
practice: 'Practice'
practice_no: 'Øv'
</translations>

<script>
import MemogeniusSystemIntroPopup from '@/components/memogenius/MemogeniusSystemIntroPopup';

export default {
    debugComponent: 'systems-shared_HomeViewDebug',
    inject: ['module', 'config'],
    computed: {
        articlePopupProps() {
            const oldSystemId = this.$store.state.moduleMemogenius.newToOldSystemIdMap[this.config.systemId];
            if (!oldSystemId) {
                return;
            }
            const articleIds = this.$t(`memogenius.techniques.${oldSystemId}.articles`);
            const articleId = articleIds[0];
            if (!articleId) {
                return;
            }
            return {
                systemId: oldSystemId,
                articleId,
                showArticleOnly: true
            };
        },
        hasArticle() {
            return !!this.articlePopupProps;
        },
        hasSubtitle() {
            return this.$i18n._root.$te(`systems.${this.config.systemId}.subtitle`);
        }
    },
    methods: {
        showArticlePopup() {
            this.$modal.open({
                component: MemogeniusSystemIntroPopup,
                target: 'app-modal',
                props: this.articlePopupProps
            });
        },
        exit() {
            this.$router.push(this.$store.state.moduleApp.memolife.systemExitTo);
        }
    }
};
</script>

<style scoped lang="scss">
.HomeView {
    display: flex;
    flex-direction: column;
}
.top {
    position: relative;
    min-height: 10em;
    flex: 1;
    padding: 2em;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.exitButton {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 1em;
    height: 3em;
    display: flex;
    align-items: center;
}
.exitIcon {
    $size: 1.2em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.7);
}
.icon {
    $size: 4em;
    width: $size;
    height: $size;
    background-color: white;
    border-radius: 0.5em;
}
.title {
    margin-top: 1em;
}
.subtitle {
    margin-top: 0.5em;
}
.middle {
    flex: 1;
    padding: 0 2em;
}
.text {
    line-height: 1.5em;
}
.bottom {
    padding: 2em 2em;
}
.secondaryButtons {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}
.buttonSecondary {
    padding: 0 1em;
    text-align: center;
}
.buttonSecondaryIcon {
    $size: 2em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
}
.buttonSecondaryLabel {
    text-transform: uppercase;
    font-size: 70%;
    color: rgba(white, 0.5);
}
.buttonGroup {
    display: flex;
    .button {
        flex: 1;
    }
    .button:last-child {
        margin-left: 1em;
    }
}
.button {
    padding: 1em;
    text-align: center;
    background-color: white;
    border-radius: 0.2em;
    color: black;
}
.browseButton {
    text-align: center;
    padding: 1em;
}
</style>
